import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  model,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { User } from '@core/entities/user/user.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { NavigationHelper } from '@core/helpers/navigation.helper';
import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, take, tap } from 'rxjs/operators';
import { SharedModule } from '@shared/shared.module';
import { UserOverviewComponent } from '@shared/components/user-overview/user-overview.component';
import {
  createEmailAddressForm,
  createNameForm,
  createNamesAndAddressForm,
  createPhoneNumberInputFormControl,
  FfNgxButtonComponent,
  FfNgxCardComponent,
  FfNgxEmailAddressCardComponent,
  FfNgxEmailAddressValidator,
  FfNgxIconComponent,
  FfNgxNameAndAddressCardComponent,
  FfNgxNameInputComponent,
  FfNgxPhoneNumberCardComponent,
  FfNgxPhoneNumberValidator,
} from '@fagforbundet/ngx-components';
import {
  FfNgxBankAccountNumberFormComponent,
} from '@shared/components/ff-ngx-bank-account-number-form/ff-ngx-bank-account-number-form.component';
import { environment } from '@environments/environment';
import { AsyncPhoneNumberValidator } from '@core/validators/async/async-phone-number.validator';
import { AsyncEmailAddressValidator } from '@core/validators/async/async-email-address.validator';
import { CountryService } from '@core/services/country/country.service';
import { PhoneNumberCountryCodeService } from '@core/services/phone-number/phone-number-country-code.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { EmailAddress } from '@core/models/email-address/email-address';
import { NameFormValue } from '@core/models/user/name-form-value';
import { PostalAddressFormValue } from '@core/models/postal-address/postal-address-form-value';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PhoneNumber } from '@core/models/phone-number/phone-number';
import { PostalCodeService } from '@core/services/postal-code/postal-code.service';
import { SelfService } from '@core/services/self/self.service';
import { mapSerializrUser } from '@core/mappers/serializr-entities/user/user.mapper';
import { PostalAddressService } from '@core/services/postal-address/postal-address.service';
import { PhoneNumberService } from '@core/services/phone-number/phone-number.service';
import { EmailAddressService } from '@core/services/email-address/email-address.service';
import { PhoneNumberFormValue } from '@core/models/phone-number/phone-number-form-value';
import { postPhoneNumberMapper } from '@core/mappers/response/id-api/post-phone-number/post-phone-number.mapper';
import {
  postSetPrimaryPhoneNumberMapper,
} from '@core/mappers/response/id-api/post-set-primary-phone-number/post-set-primary-phone-number.mapper';
import {
  postRequestPhoneNumberVerificationMapper,
} from '@core/mappers/response/id-api/post-request-phone-number-verification/post-request-phone-number-verification.mapper';
import { postEmailAddressMapper } from '@core/mappers/response/id-api/post-email-address/post-email-address.mapper';
import { PostalAreaLookupResponse } from '@core/models/postal-address/postal-area-lookup-response';
import { mapSerializrEmailAddressEntity } from '@core/mappers/serializr-entities/user/email-address.mapper';
import { mapSerializrPhoneNumber } from '@core/mappers/serializr-entities/user/phone-number.mapper';

@Component({
  selector: 'app-user-info-update',
  templateUrl: './user-info-update.component.html',
  styleUrls: ['./user-info-update.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    UserOverviewComponent,
    FfNgxNameAndAddressCardComponent,
    FfNgxPhoneNumberCardComponent,
    FfNgxEmailAddressCardComponent,
    FfNgxButtonComponent,
    FfNgxBankAccountNumberFormComponent,
    FfNgxCardComponent,
    FfNgxIconComponent,
    FfNgxNameInputComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoUpdateComponent implements OnInit, ProcessableInterface {
  readonly #cdr = inject(ChangeDetectorRef);
  readonly #postalCodeService = inject(PostalCodeService);
  readonly #selfService = inject(SelfService);
  readonly #countryService = inject(CountryService);
  readonly #postalAddressService = inject(PostalAddressService);
  readonly #emailAddressService = inject(EmailAddressService);
  readonly #phoneNumberService = inject(PhoneNumberService);
  readonly #phoneNumberCountryCodeService = inject(PhoneNumberCountryCodeService);
  readonly #asyncEmailAddressValidator = inject(AsyncEmailAddressValidator);
  readonly #asyncPhoneNumberValidator = inject(AsyncPhoneNumberValidator);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #authService = inject(AuthenticationService);
  protected debugSections = environment.frontEnd.userInfoUpdateForm.debugSections;
  protected readonly countries = toSignal(this.#countryService.fetchCountries(), { initialValue: [] });
  protected readonly countryCodes = toSignal(this.#phoneNumberCountryCodeService.fetchCountryCodes(), { initialValue: [] });
  protected nameAndAddressFormProcessors = signal(0);
  pendingInformationUpdates = model.required<PendingInformationUpdates>();
  user = model.required<User>();
  user$ = toObservable(this.user);
  @Output()
  proceedCallback: EventEmitter<boolean> = new EventEmitter();
  processing: boolean = false;
  warningSubject = new BehaviorSubject<string[] | null>(null);
  warnings$ = this.warningSubject.asObservable();
  names = signal<NameFormValue | null>(null);
  bankAccountNumber$ = new BehaviorSubject<string | null>(null);
  address = signal<PostalAddressFormValue | null>(null);
  namesAndAddressForm = createNamesAndAddressForm(this.names(), this.address());
  namesForm = createNameForm(this.names());
  phoneNumbers = signal<(PhoneNumber & { isValid?: boolean })[]>([]);
  emailAddresses = signal<(EmailAddress & { isValid?: boolean })[]>([]);
  emailAddressCardForm = this.#getEmailAddressCardForm();
  phoneNumberCardForm = this.#getPhoneNumberCardForm();

  ngOnInit() {
    this.user$.subscribe((user) => {
      this.names.set({
        firstName: user.firstName,
        lastName: user.lastName,
      });

      this.bankAccountNumber$.next(user.bankAccount);

      this.address.set({
        uuid: user.postalAddresses[0].uuid,
        postalArea: user.postalAddresses[0].postalArea,
        postalCode: user.postalAddresses[0].postalCode,
        country: user.postalAddresses[0].country,
        countryCode: user.postalAddresses[0].countryCode,
        line1: user.postalAddresses[0].line1,
        line2: user.postalAddresses[0].line2,
        line3: user.postalAddresses[0].line3,
      });

      this.emailAddresses.set(user.emails.map((e) => {
        return {
          ...mapSerializrEmailAddressEntity(e),
          isValid: FfNgxEmailAddressValidator.syntax()(new FormControl({
            value: e.emailAddress,
            disabled: false,
          })) === null,
        };
      }));
      this.phoneNumbers.set(user.phones.map((p) => {

        return {
          ...mapSerializrPhoneNumber(p),
          isValid: FfNgxPhoneNumberValidator.syntax()(new FormControl({
            value: p.phoneNumber,
            disabled: false,
          })) === null,
        };
      }));

      this.#initNamesAndAddressForm();
      this.#initPhoneNumberCardForm();
      this.#initEmailAddressCardForm();

      this.#cdr.detectChanges();
    });
  }

  logout(): void {
    if (this.processing) {
      return;
    }

    this.#authService
      .logout()
      .pipe(
        take(1),
        finalize(() => {
          NavigationHelper.redirect(
            this.#activatedRoute.snapshot.paramMap.get('redir') || EnvironmentHelper.getLogoutUrl(),
          );
        }),
      )
      .subscribe();
  }

  namesAndAddressFormSubmitted(value: {
    names: NameFormValue;
    postalAddress: PostalAddressFormValue;
  }): void {
    this.nameAndAddressFormProcessors.update((p) => {
      return p + 1;
    });
    this.#selfService.putUserNames(
      mapSerializrUser(this.user()),
      value.names,
    ).pipe(
      finalize(() => {
        this.nameAndAddressFormProcessors.update((p) => {
          return p - 1;
        });
      }),
    ).subscribe();
    if (value.postalAddress.uuid) {
      this.nameAndAddressFormProcessors.update((p) => {
        return p + 1;
      });
      this.#postalAddressService.putPostalAddress(value.postalAddress).pipe(
        finalize(() => {
          this.nameAndAddressFormProcessors.update((p) => {
            return p - 1;
          });
        }),
      ).subscribe();
    } else {
      this.nameAndAddressFormProcessors.update((p) => {
        return p + 1;
      });
      this.#postalAddressService.postPostalAddress(value.postalAddress, this.user().uuid).pipe(
        finalize(() => {
          this.nameAndAddressFormProcessors.update((p) => {
            return p - 1;
          });
        }),
      ).subscribe();
    }
  }

  namesFormSubmitted(): void {
    this.#selfService.putUserNames(
      mapSerializrUser(this.user()),
      this.namesForm.getRawValue(),
    ).subscribe((r) => {
      this.names.set({
        firstName: r.user.firstName,
        lastName: r.user.lastName,
      });
      this.resetNamesForm();
      this.#cdr.detectChanges();
    });
  }

  putBankAccountNumber = (bankAccountNumber: string): Observable<string> => {
    return this.#selfService.putBankAccountNumber(
      mapSerializrUser(this.user()),
      bankAccountNumber,
    ).pipe(
      tap((response) => {
        this.bankAccountNumber$.next(response.user.bankAccount);
        this.user.update((u) => {
          u.bankAccount = response.user.bankAccount;
          return u;
        });
      }),
      map((r) => {
        return r.user.bankAccount;
      }),
    );
  };

  postalCodeLookup = (
    postalCode: string,
  ): Observable<PostalAreaLookupResponse> => {
    return this.#postalCodeService.postalCodeLookup(postalCode).pipe(
      map((response) => {
        return {
          ...response.postalCode,
        };
      }),
    );
  };

  submitNewPhoneNumber = (
    phoneNumber: PhoneNumberFormValue,
  ): Observable<PhoneNumber> => {
    return this.#phoneNumberService.postPhoneNumber(phoneNumber, this.user().uuid).pipe(
      map((r) => {
        const newPhoneNumber = {
          ...postPhoneNumberMapper(r.phone),
          isValid: true,
        };
        this.phoneNumbers.update((existing: PhoneNumber[]) => {
          return [...existing, newPhoneNumber];
        });
        this.#resetPhoneNumberCardForm();
        return newPhoneNumber;
      }),
    );
  };

  deletePhoneNumber = (phoneNumber: PhoneNumber): Observable<void> => {
    return this.#phoneNumberService.deletePhoneNumber(phoneNumber).pipe(
      tap(() => {
        this.phoneNumbers().splice(this.phoneNumbers().indexOf(phoneNumber), 1);

        this.#resetPhoneNumberCardForm();
      }),
      map(() => {
        return undefined;
      }),
    );
  };

  requestSendVerificationSms = (phoneNumber: PhoneNumber): Observable<void> => {
    return this.#phoneNumberService.postRequestSendVerificationSms(phoneNumber).pipe(
      map(() => {
        return undefined;
      }),
    );
  };

  setPrimaryPhoneNumber = (phoneNumber: PhoneNumber): Observable<void> => {
    return this.#phoneNumberService.postSetPrimaryPhoneNumber(phoneNumber).pipe(
      tap((r) => {
        const updatedPhoneNumber = postSetPrimaryPhoneNumberMapper(r.phone);
        this.phoneNumbers.update((existing: PhoneNumber[]) => {
          return existing.map((pn) => {
            pn.primary = pn.uuid === updatedPhoneNumber.uuid;
            return pn;
          });
        });
      }),
      map(() => {
        return undefined;
      }),
    );
  };

  postPhoneNumberVerification = (
    phoneNumber: PhoneNumber,
    code: string,
  ): Observable<void> => {
    return this.#phoneNumberService.postPhoneNumberVerification(phoneNumber, code).pipe(
      tap((r) => {
        const updatedPhoneNumber = postRequestPhoneNumberVerificationMapper(r.phone);
        this.phoneNumbers.update((phoneNumbers: PhoneNumber[]) => {
          return phoneNumbers.map((pn) => {
            if (updatedPhoneNumber.primary) {
              pn.primary = false;
            }

            if (pn.uuid === updatedPhoneNumber.uuid) {
              pn.primary = updatedPhoneNumber.primary;
              pn.verifiedAt = updatedPhoneNumber.verifiedAt;
            }

            return pn;
          });
        });
      }),
      map(() => {
        return undefined;
      }),
    );
  };

  submitNewEmailAddress = (emailAddress: string): Observable<EmailAddress> => {
    return this.#emailAddressService.postEmailAddress(emailAddress, this.user().uuid).pipe(
      map((r) => {
        const newEmailAddress = {
          ...postEmailAddressMapper(r.email),
          isValid: true,
        };
        this.emailAddresses.update((existing: EmailAddress[]) => {
          return [...existing, newEmailAddress];
        });
        this.#resetEmailAddressCardForm();

        return newEmailAddress;
      }),
    );
  };

  deleteEmailAddress = (emailAddress: EmailAddress): Observable<void> => {
    return this.#emailAddressService.deleteEmailAddress(emailAddress).pipe(
      tap(() => {
        this.emailAddresses().splice(
          this.emailAddresses().indexOf(emailAddress),
          1,
        );

        this.#resetEmailAddressCardForm();
      }),
      map(() => {
        return undefined;
      }),
    );
  };

  requestEmailAddressVerification = (
    emailAddress: EmailAddress,
  ): Observable<void> => {
    return this.#emailAddressService.postRequestEmailAddressVerification(emailAddress).pipe(
      map(() => {
        return undefined;
      }),
    );
  };

  setPrimaryEmailAddress = (emailAddress: EmailAddress): Observable<void> => {
    return this.#emailAddressService.postSetPrimaryEmailAddress(emailAddress).pipe(
      tap(() => {
        this.emailAddresses.update((existing: EmailAddress[]) => {
          return existing.map((ea) => {
            ea.primary = ea.uuid === emailAddress.uuid;
            return ea;
          });
        });
      }),
      map(() => {
        return undefined;
      }),
    );
  };

  confirm(): void {
    if (!this.#isValid()) {
      this.#displayWarningMessages();

      return;
    }

    this.warningSubject.next(null);

    this.processing = true;
    this.proceedCallback.next(true);
  }

  resetNamesForm(): void {
    this.namesForm = createNameForm(this.names());
    if (this.namesForm.valid) {
      this.namesForm.disable();
    }
  }

  resetNamesAndAddressCardForm(): void {
    this.namesAndAddressForm = createNamesAndAddressForm(this.names(), this.address());
    if (this.namesAndAddressForm.valid) {
      this.namesAndAddressForm.disable();
    }
  }

  #resetPhoneNumberCardForm(): void {
    this.phoneNumberCardForm = this.#getPhoneNumberCardForm();
    this.phoneNumberCardForm.disable();
  }

  #getPhoneNumberCardForm() {
    return new FormGroup({
      phoneNumber: createPhoneNumberInputFormControl({
        phoneNumber: null,
        validators: [Validators.required, FfNgxPhoneNumberValidator.syntax(), FfNgxPhoneNumberValidator.unique(this.phoneNumbers())],
        asyncValidators: [this.#asyncPhoneNumberValidator.validate],
      }),
    });
  }

  #resetEmailAddressCardForm(): void {
    this.emailAddressCardForm = this.#getEmailAddressCardForm();
    this.emailAddressCardForm.disable();
  }

  #getEmailAddressCardForm() {
    return createEmailAddressForm({
      emailAddress: null,
      validators: [Validators.required, FfNgxEmailAddressValidator.syntax(), FfNgxEmailAddressValidator.unique(this.emailAddresses())],
      asyncValidators: [this.#asyncEmailAddressValidator.validate],
    });
  }

  #isValid(): boolean {
    return this.#allFormsAreDisabledOrValid() && this.#mainEmailAddressIsValid() && this.#mainPhoneNumberIsValid();
  }

  #mainEmailAddressIsValid(): boolean {
    let isValid = false;

    this.emailAddresses().forEach((ea) => {
      if (ea.primary && ea.isValid) {
        isValid = true;
      }
    });

    return isValid;
  }

  #mainPhoneNumberIsValid(): boolean {
    let isValid = false;

    this.phoneNumbers().forEach((pn) => {
      if (pn.primary && pn.isValid) {
        isValid = true;
      }
    });

    return isValid;
  }

  #initNamesAndAddressForm(): void {
    if (
      this.pendingInformationUpdates().user.displayAllowed &&
      this.pendingInformationUpdates().address.displayAllowed
    ) {
      this.resetNamesAndAddressCardForm();
    } else {
      this.namesAndAddressForm.disable();
    }

    if (
      this.pendingInformationUpdates().user.displayAllowed &&
      !this.pendingInformationUpdates().address.displayAllowed
    ) {
      this.resetNamesForm();
    } else {
      this.namesForm.disable();
    }
  }

  #initPhoneNumberCardForm(): void {
    if (this.pendingInformationUpdates().phone.displayAllowed) {
      this.#resetPhoneNumberCardForm();
    } else {
      this.phoneNumberCardForm.disable();
    }
  }

  #initEmailAddressCardForm(): void {
    if (this.pendingInformationUpdates().phone.displayAllowed) {
      this.#resetEmailAddressCardForm();
    } else {
      this.emailAddressCardForm.disable();
    }
  }

  #displayWarningMessages(): void {
    const messages = [];
    if (!this.#namesFormValid()) {
      messages.push('Det ser ut til å være noe som ikke helt stemmer i navneskjemaet.');
    }

    if (!this.#namesAndAddressFormValid()) {
      messages.push('Det ser ut til å være noe som ikke helt stemmer i navn- og adresseskjemaet.');
    }

    if (!this.#employmentSectionValid()) {
      messages.push('Det ser ut som vi mangler kontonummeret ditt.');
    }

    if (!this.#phoneNumberCardFormValid()) {
      messages.push('Det ser ut til å være noe som ikke helt stemmer i telefonnummerskjemaet.');
    }

    if (!this.#emailAddressCardFormValid()) {
      messages.push('Det ser ut til å være noe som ikke helt stemmer i e-postadresseskjemaet.');
    }

    if (!this.#mainPhoneNumberIsValid()) {
      messages.push('Hovedtelefonnummeret ser ut til å være ugyldig.');
    }

    if (!this.#mainEmailAddressIsValid()) {
      messages.push('Hovede-postadressen ser ut til å være ugyldig.');
    }

    this.warningSubject.next(messages);
  }

  #allFormsAreDisabledOrValid(): boolean {
    return (
      this.#namesFormValid() &&
      this.#namesAndAddressFormValid() &&
      this.#phoneNumberCardFormValid() &&
      this.#emailAddressCardFormValid() &&
      this.#employmentSectionValid()
    );
  }

  #namesFormValid(): boolean {
    return this.namesForm.disabled || this.namesForm.valid;
  }

  #namesAndAddressFormValid(): boolean {
    return this.namesAndAddressForm.disabled || this.namesAndAddressForm.valid;
  }

  #phoneNumberCardFormValid(): boolean {
    return this.phoneNumberCardForm.disabled || this.phoneNumberCardForm.valid;
  }

  #emailAddressCardFormValid(): boolean {
    return this.emailAddressCardForm.disabled || this.emailAddressCardForm.valid;
  }

  #employmentSectionValid(): boolean {
    return !((
      this.debugSections.bankAccountNumber ||
      !!this.pendingInformationUpdates().employmentInfo?.required
    ) && !this.user().bankAccount);
  }
}
